<template>
    <span
        class="PromotionBarText"
        :class="{ 'PromotionBarText--with-custom-text-colour': textColour }"
    >
        <div
            v-if="slice.primary.text_icon && slice.primary.text_icon !== 'None'"
            :class="{
                'PromotionBarText__icon--rotate':
                    slice.primary.text_icon.toLowerCase() === 'platform12',
            }"
        >
            <FontIcon
                :icon="slice.primary.text_icon.toLowerCase()"
                :style="{ '--font-icon-colour': slice.primary.text_colour }"
                size="x-small"
            />
        </div>
        <span class="PromotionBarText__text">{{ slice.primary.text }}</span>
    </span>
</template>

<script setup lang="ts">
import { type Content } from '@prismicio/client'

// The array passed to `getSliceComponentProps` is purely optional.
// Consider it as a visual hint for you when templating your slice.
const props = defineProps(
    getSliceComponentProps<Content.PromotionBarTextSlice>([
        'slice',
        'index',
        'slices',
        'context',
    ]),
)

const textColour = ref(props.slice.primary.text_colour)
</script>

<style lang="scss" scoped>
.PromotionBarText {
    display: flex;
    align-items: center;
    column-gap: 0.25rem;
    color: var(--black);
    font-weight: var(--font-weight-bold);
    max-width: 99%;

    &::after {
        @include medium-up {
            content: '';
            background-color: var(--lime-green);
            width: 0.4rem;
            height: 0.4rem;
            margin-left: 0.75rem;
            margin-right: 0.75rem;
            transform: rotate(45deg);
            transition: transform 0.4s cubic-bezier(0.34, 1.56, 0.64, 1);
        }
    }
}

.PromotionBarText--with-custom-text-colour {
    color: v-bind(textColour);

    &::after {
        background-color: v-bind(textColour);
    }
}

.PromotionBarText__text {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.PromotionBarText__icon--rotate {
    animation: rotate 7s infinite forwards linear;
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>
